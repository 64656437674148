import PropTypes, { array } from "prop-types"
import React from "react"
import * as BingoItemStyles from "./bingoItem.module.scss"
import { motion } from "framer-motion"

const onClickFunction = (id, allIds) => {
  if (allIds.includes(id)) {
    var index = allIds.indexOf(id)
    allIds.splice(index, 1)
  } else {
    allIds.push(id)
  }

  var links = allIds.join(",")
  window.open("/bingo?" + links, "_self")
}

const BingoItem = ({ name, id, allIds }) => {
  var classNames = [BingoItemStyles.container]
  if (allIds.includes(id)) {
    classNames.push(BingoItemStyles.selected)
  }
  return (
  <motion.div
    className={classNames.join(" ")}
    whileHover={{scale: 1.1}}
    whileTap={{scale: 0.9}}
    onClick={() => {onClickFunction(id, allIds)}}
  >
    <div className={BingoItemStyles.text}>
      {name}
    </div>
  </motion.div>
)}

BingoItem.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  allIds: PropTypes.arrayOf(PropTypes.number)
}

BingoItem.defaultProps = {
  name: ``,
}

export default BingoItem
