import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import BingoItem from "../components/bingoItem"
import SEO from "../components/seo"

const bingoValues = [
  "Iemand scheld",
  "Iemand springt",
  "Vegetarisch",
  "Vlees",
  "Teleurstelling",
  "Het regent",
  "Hobbelen",
  "Kom op",
  "Sneller",
  "Gieren",
  "Brak",
  "Zuipen",
  "San Francisco",
  "Iemand valt",
  "Dom",
  "Geld",
  "Coronabier",
  "Dik",
  "Moe",
  "Scheikunde",
  "Biologie",
  "Vies",
  "Geitje",
  "Te laat",
  "Zon!",
]

const BingoPage = () => 
{
  const [currentIds, setCurrentIds] = useState([])

  useEffect(() => {
    var currentIdsString = window.location.href.split("?")
    var currentIds = []
    if (currentIdsString.length > 1) {
      var currentIdsStrings = currentIdsString[1].split(",")
      currentIdsStrings.forEach((idString) => {
        var idNumber = parseInt(idString)
        if (!isNaN(idNumber)) {
          currentIds.push(idNumber)
        }
      })
    }
    setCurrentIds(currentIds)
  }, [])

  var gridItems = bingoValues.map((bingoName, index) => {
    return <BingoItem key={index} name={bingoName} id={index} allIds={currentIds}/>
  })

  return (
  <Layout>
    <SEO title="Home" />
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, minmax(150px, 14vw))', padding: '1rem', paddingRight: '4rem' }}>
      {gridItems}
    </div>
  </Layout> )
}

export default BingoPage
